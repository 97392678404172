body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: white;
}


.whole-container {
  background-image: url('./assets/sky.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.whole-container h1 {
  text-decoration: underline #5a00ba;
  text-decoration-thickness: 5px;
}

header {
  text-align: center;
  padding: 20px;
  background-color: transparent; /* Set the background color to transparent */
  color: #fff;
  height: 100vh; /* Set header height to 100% of viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

#portfolio img {
  position: absolute;
}

#portfolio button {
  cursor: pointer;
}

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.921);
  padding: 20px;
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 1000;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.navbar ul li {
  display: inline-block;
  margin-left: 10px;
}

.navbar ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
  padding: 5px 10px;
  cursor: pointer; /* Add cursor property */
}

.navbar ul li a.active,
.navbar ul li a:hover {
  color:#5a00ba;
}

.navbar.show {
  opacity: 1;
}

.section {
  padding: 50px 20px;
  height: 100vh;
}

.about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

#about span {
  font-size: 40px;
  text-align: center;
}


.about-left,
.about-right {
  max-width: 500px;
  text-align: center;
}

.about-left img {
    max-width: 100%;
}

.about-left p {
  font-size: 20px;
}

.about-right img {
  max-width: 100%;
}


@media (max-width: 1510px) {
  .about-left img,
  .about-right img{
    max-width: 95%; /* Set the maximum width of both sections to 100% */
  }

  .about-left p {
    font-size: 19px;
  } 

  .project-container {
    flex-wrap: wrap;
    justify-content: center !important;
    padding-left: 0% !important;
    width: 100%;
  }
  .project-image {
    width: 60% !important;
    height: 70%;
  }



}


@media (max-width: 1300px) {
  .about-left img,
  .about-right img{
    max-width: 90%; /* Set the maximum width of both sections to 100% */
  }

  .about-left p {
    font-size: 18px;
  } 
  
}

@media (max-width: 1080px) {
  .about-left img,
  .about-right img{
    max-width: 60%; /* Set the maximum width of both sections to 100% */
  }

  .about-left p {
    font-size: 17px;
  } 

  .about-left h1 {
    font-size: 25px;
  }
  
}

.head-container {
  width: 100%;
  height: 80%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.head-container span {
  font-size: 50px;
  margin-top: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
}

.head-container h1 {
  margin-top: 10px;
  margin-bottom: 10px;
}


#portfolio {
  position: relative;
  width: 100%;
  height: 100vh; /* Set height to 100% of viewport height */
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#portfolio video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Set video width to 100% of viewport width */
  height: 100%; /* Set video height to 100% of viewport height */
  object-fit: cover; /* Maintain video aspect ratio and cover the entire section */
  z-index: -1; /* Ensure the video is behind other elements */
}

#projects span{
  text-align: center;
  font-size: 40px;
}

#projects {
  width: 100%;
  margin: 0%;
  padding: 0%;
}

.project-container {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: start;
  padding-left: 8%;
}

.image-text {
  width: 700px;
  
}

.image-text h1 {
  text-decoration: none;
  margin-top: 10%;
  text-align: center;
  font-size: 50px;
}
.image-text h3 {
  text-align: center;
  font-size: 22px;
}

.project-image {
  width: 50%;
  position: relative;

}

.project-image img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 80%;
  width: 90%;
}

.image-background {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(135deg, #5a00ba 40%, #0147d4);
  display: flex;
  justify-content: end;
}


#contact {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0%;
  display: flex;
  flex-direction: column;
  
}

.contact-header span {
  text-align: center;
}

.head {
  font-size: 40px;
}

.contact-header h3 {
  font-size: 20px;
}



.contact-form {
  max-width: 400px;
  margin: auto;
  
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.form-control {
  width: calc(100% - 22px); /* Adjusted width */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding and border are included in the width calculation */
}

textarea.form-control {
  height: 120px;
  resize: none;
  overflow-y: auto;
  width: calc(100% - 22px) !important; /* Override inline style */

}


.btn:hover {
  background-color: #0056b3;
}

.error-message {
  color: #5a00ba;
  margin-top: 10px;
}



.footer-container {
  height: auto;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}

.icon {
  transform: scale(2);
}

.icon:hover {
  transform: scale(2.2);
}

.footer p {
  margin-top: 25px;
}

a {
  color: inherit; /* Change the color to inherit from its parent */
  text-decoration: none; /* Remove underline */
}
button {
  margin-top: 50px;
  align-items: center;
  background-image: linear-gradient(135deg, #5a00ba 40%, #0147d4);
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: "Codec cold",sans-serif;
  font-size: 30px;
  font-weight: 700;
  height: 54px;
  width: 200px;
  justify-content: center;
  letter-spacing: .4px;
  line-height: 1;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

button:active {
  outline: 0;
}

button:hover {
  outline: 0;
  transform: scale(1.1);
}

button span {
  transition: all 200ms;
}

button:hover span {
  transform: scale(.9);
  opacity: .75;
}

.contact-header button {
  font-size: 20px;
  margin-top: 0;
}



@media (max-width: 1673px) {
  .image-text {
    font-size: 10px;
  }

  .image-background img {
    align-self: center;
  }
  
}


@media (0px <= width <= 450px) {

  
  .head-container span{
    font-size: 12px;
  }

  .head-container button {
    width: 36%;
    font-size: 17px;
  }
  
  .navbar {
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
  }

  .navbar ul {
    text-align: center;
  }

  .whole-container h1 {
    font-size: 100%;
  }


  .about-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .about-right img {
    width: 100%;
  }

  .about-languages {
    width: 100%;
  }

  .about-container p {
    font-size: 14px;
  }

  .image-text h1{
    font-size: 26px;
  }

  .image-text h3 {
    font-size: 14px;
  }

  .project-image {
    width: 90% !important;
  }

  #contact h3{
    font-size: 100%;
  }

  .contact-form {
    text-align: center;
  }

  .contact-form label {
    text-align: start;
    margin-left: 3%;
  }

  .contact-header button {
  text-align: center;
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
  font-size: 12.75px;
  }

  .footer span {
    font-size: 12px;
  }

  .icon {
    transform: scale(1.4);
  }

  .footer-container {
    justify-content: end;
  }

  .footer p {
    margin-top: 10px;
  }
}


@media (0px <= height <= 700px) {
  .footer-container {
    margin-bottom: 20px;
  }
}

@media (700px < height <= 750px) {
  .footer-container {
    margin-bottom: 20px;
  }
  .section {
    height: 92vh;
  }

  .icon {
    transform: scale(1.7);
  }

  .footer p {
    margin-top: 12px;
  }
}

@media (750px < height <= 800px) {
  .footer-container {
    margin-bottom: 20px;
  }
  .section {
    height: 90vh;
  }

  .icon {
    transform: scale(1.7);
  }

  .footer p {
    margin-top: 12px;
  }
}

@media (800px < height <= 850px) {
  .footer-container {
    margin-bottom: 20px;
  }
  .section {
    height: 82vh;
  }

  .icon {
    transform: scale(1.9);
  }

  .footer p {
    margin-top: 12px;
  }
}

@media (850px < height <= 900px) {
  .footer-container {
    margin-bottom: 20px;
  }
  .section {
    height: 80vh;
  }

  .icon {
    transform: scale(1.9);
  }

  .footer p {
    margin-top: 12px;
  }
}

@media (900px < height <= 932px) and (max-width: 440px) {
  .footer-container {
    margin-bottom: 20px;
  }
  .section {
    height: 76vh;
  }

  .icon {
    transform: scale(1.9);
  }

  .footer p {
    margin-top: 12px;
  }
}